.tbSucursales, .tbAreas, .btnConsultar{
    width: 25%;
    margin: 0px 0px 0px 40px
}

.btnConsultar{
    width: 20%;
    height: 35px !important;
    margin: 8px 0px 0px 40px
}

.dbDesde, .dbHasta {
    width: 20%;
}

.dgvResultados {
    margin: 20px 0px 0px 0px;
}

.btnEvaluacion {
    font-size: 26px;
    cursor: pointer;
    color: #999;
}

.btnEvaluacion:hover {
    color: #000;
    font-size: 28px;
    transition: color 0.2s, font-size 0.2s;
}

.btnEvaluacion:active {
    color: #999;
    transition: color 0.1s, font-size 0.1s;
}

.btnViewFile{
    cursor: pointer;
    color: #999;
    font-size: 14px;
    // border: 2px solid #000; /* Borde gris */
    // border-radius: 5px;
}
.btnViewFile:hover{
    color: #000;
    font-size: 15px;
    transition: color 0.2s, font-size 0.2s;
}
.btnViewFile:active{
    color: #999;
    transition: color 0.1s, font-size 0.1s;
}

.tgEvaluacion {
    display: grid !important;
    width: auto;
    height: auto;
    border: 1px solid #ddd;
    border-radius: 8px;
}

.tgEvaluacion:hover .tgCategorie{
    font-size: 14px;
    color: #fa7e02;
    transition: font-size .4s, color .4s;
}

.tgCategorie{
    font-size: 13px;
    margin: 10px 0px 0px 15px;
    color: #aba9a9;
    white-space: normal;
}

.tgTitle{
    font-size: 16px;
    margin: 9px 3px 15px 20px;
    white-space: normal;
}