.btnTarea {
    width: 100%;
    height: auto;
}

.IconoTarea {
    width: 200px;
    height: -webkit-fill-available;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: visible;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
}

.ImgIcono {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.TareaItem {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: -webkit-fill-available;
    padding: 8px 12px 8px 8px;
    border-radius: 4px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
}

.task-item-text,
.task-item-info {
  margin: 3px 0px -4px 0px;
  padding: 0 24px 0 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.task-item-info {
  font-size: 16px;
  opacity: 0.6;
}

.TabPanelTareas {
  overflow-y: auto !important;
}

.ContenedorEvaluations {
  overflow: hidden;
}