@import "./themes/generated/variables.base.scss";

body {
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.app {
  background-color: darken($base-bg, 5.00);
  display: flex;
  height: 100%;
  width: 100%;

  .content {
    line-height: 1.5;
    flex-grow: 1;

    h2 {
      font-size: 30px;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }

  .container {
    height: 100%;
    flex-direction: column;
    display: contents;
  }

  .layout-body {
    flex: 1;
    min-height: 0;
  }

  .content-block {
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 20px;
  }
}

.side-nav-outer-toolbar .dx-drawer {
  height: calc(100% - 56px)
}

.screen-x-small .content-block {
  margin-left: 20px;
  margin-right: 20px;
}

.responsive-paddings {
  padding: 20px;

  .screen-large & {
    padding: 40px;
  }
}

.dx-card.wide-card {
  border-radius: 0;
  margin-left: 0;
  margin-right: 0;
  border-right: 0;
  border-left: 0;
}

.with-footer > .dx-scrollable-wrapper >
.dx-scrollable-container > .dx-scrollable-content {
  height: 100%;

  & > .dx-scrollview-content {
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }
}

$side-panel-min-width: 60px;

html, body {
  margin: 0px;
  min-height: 100%;
  height: 100%;
}

#root {
  height: 100%;
}

* {
  box-sizing: border-box;
}



/* Halcoloader estilos */

.halcoloader {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: 10000;
  overflow: hidden;
  visibility: hidden;
  opacity: 0;
}

  .halcoloader.active {
      opacity: 0.9;
      visibility: visible;
  }

.halcoloader-logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

path#Trazado_1 {
  stroke: url(#linear-gradient);
  fill: none;
  stroke-dasharray: 1800;
  stroke-dasharray: 2855;
  opacity: 10;
  animation: animationThree 2.5s cubic-bezier(0.65, 0.05, 0.36, 1) infinite alternate;
}


#Trazado_2,
#Trazado_3,
#Trazado_4,
#Trazado_5,
#Trazado_6,
#Trazado_7,
#Trazado_8,
#Trazado_9,
#Trazado_10,
#Trazado_11{
    // stroke: url(#linear-gradient);
    // fill: none;
    // // stroke-dasharray: 1800;
    // // stroke-dasharray: 2855;
    // opacity: 10;
    animation: animationOne .8s cubic-bezier(0.65, 0.05, 0.36, 1) infinite alternate;
     /* animation: animationTwo 2s cubic-bezier(0.65, 0.05, 0.36, 1) infinite alternate; */
    //  animation: animationThree 2.5s cubic-bezier(0.65, 0.05, 0.36, 1) infinite alternate;
}

@keyframes animationOne {
  0% {
      opacity: 0;
  }

  100% {
      opacity: 1;
  }
}

@keyframes animationTwo {
  0% {
      opacity: 0;
      fill: #fff;
  }

  100% {
      opacity: 100;
      fill: url(#linear-gradient-2);
  }
}

@keyframes animationThree {
  0% {
      opacity: 0;
      fill: none;
      stroke-dashoffset: 0;
  }
 
  100% {
      opacity: 10;
      stroke-dashoffset: 2855;
  }
}
