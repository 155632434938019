.btnNuevaTarea {
    margin: 0% 2% 0% 48%;
    width: 15%;
    height: 20%;
}

.btnNuevaCategoria {
    margin: 0% 2% 0% 0%;
    width: 15%;
    height: 20%;
}
.btnCategorias {
    margin: 0% 0% 0% 0%;
    width: 15%;
    height: 20%;
}

.AccordionCategories {
    margin: 20px 0px 0px 35px;
    width: 95%;
}

.listCategoriesContent {
    .ImgCategories {
        width: 55px;
        height: 55px;
        object-fit: contain;
    }
}

.lbTitleCategories {
    font-size: 20px;
    margin: 18px 0px 0px 25px;
}

.listCategoriesContent {
    display: flex;
    align-items: center;
    // gap: 0px; /* Espacio entre los elementos */
}

.menuTasks{
    font-size: 20px;
    height: 6.5%;
    margin-left: auto; /* Mueve el componente al lado derecho del contenedor */
    cursor: pointer !important;
}

.dx-item{
    cursor: auto;
}


.IconEvaluacion{
    position: sticky;
    top: 0;
    font-size: 30px;
    margin: 15px 0px auto 20px; /* Ajusta el margen derecho según sea necesario */
}