.BottonAtras{
  border-width: 1px;
  border-radius: 50%;
  display: flex;
  padding: 16px 32px;
  cursor: pointer;
}

.btnAtras{
  cursor: pointer;
}

.TituloCategoria {
  flex-direction: row;
  display: flex;
  height: 6rem;
}

.divImagenCategoria {
  width: 20%;
  margin-right: 1rem;
  margin-left: 32px;
  justify-content: center;
}

.imagenTitulo {
  border-radius: 50px;
  margin: 5px;
  width: 5rem;
  
  align-self: center;
}

.divTituloCategoria {
  width: 80%;
}

.tabpanel {
  display: flex;
  height: 100%;
}

.widget-container {
  display: flex;
  justify-content: center;
  flex-grow: 1;
  min-width: 360px;
  max-height: 70vh;
  padding: 16px 32px;
}

.TabPanelTareas {
  width: 100vh;
  max-height: 80vh;
}

.dx-theme-material .widget-container {
  background-color: rgba(191, 191, 191, 0.15);
}

.dx-tabpanel-tabs-position-left .dx-tabpanel-container,
.dx-tabpanel-tabs-position-right .dx-tabpanel-container {
  width: 0;
}

.dx-viewport:not(.dx-theme-generic) .dx-tabpanel {
  border-radius: 8px;
  overflow: clip;
}

.dx-tabs-vertical {
  min-width: 120px;
}

.options {
  display: inline-flex;
  flex-direction: column;
  flex-shrink: 0;
  box-sizing: border-box;
  width: 272px;
  padding: 20px;
  background-color: rgba(191, 191, 191, 0.15);
}

.caption {
  font-weight: 500;
  font-size: 18px;
}

.option {
  margin-top: 20px;
}

.tabpanel-item {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 24px;
}

.task-item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 50px;
  padding: 8px 12px 8px 8px;
  border-radius: 4px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
}

.task-item::before {
  content: "";
  position: absolute;
  top: 8px;
  left: 6px;
  bottom: 8px;
  width: 3px;
  border-radius: 4px;
}

.task-item-priority-high::before {
  background-color: #e1bee7;
}

.task-item-priority-medium::before {
  background-color: #ffe0b2;
}

.task-item-priority-low::before {
  background-color: #c8e6c9;
}

.task-item-text,
.task-item-info {
  margin: 0;
  padding: 0 24px 0 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.task-item-info {
  font-size: 12px;
  opacity: 0.6;
}

.task-item-pseudo-button {
  position: absolute;
  right: 8px;
  top: 50%;
  font-size: 18px;
  transform: translateY(-50%);
  cursor: pointer;
  opacity: 0.6;
}

.dx-color-scheme-contrast .task-item {
  border: 1px solid #fff;
}

.dx-theme-fluent.dx-color-scheme-blue-dark .task-item {
  background-color: #1f1f1f;
}

.TareaItem {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 90%;
    height: auto;
    padding: 8px 12px 8px 8px;
    border-radius: 4px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
}

.IconoTarea {
  width: 100px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: visible;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
}

.ImgIcono{
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.btnTarea {
  height: 100px !important;
}

.dx-button {
  height: auto;
}

.dx-button-content {
  line-height: 1.2;
  height: auto;

  // flex: 1 1 auto;
  // height: 100%;
   max-height: 300%;
  display: inherit;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  // line-height: 1;

}
