.btnNuevaEvaluacion {
    margin: 0% 2% 0% 80%;
    width: 20%;
    height: 20%;
}

.AccordionCategories {
    margin: 20px 0px 0px 35px;
    width: 95%;
}

.listCategoriesContent {
    .ImgCategories {
        width: 55px;
        height: 55px;
        object-fit: contain;
    }
}

.lbTitleCategories {
    font-size: 20px;
    margin: 18px 0px 0px 25px;
}

.listCategoriesContent {
    display: flex;
    align-items: center;
}

.menuEvaluations {
    font-size: 20px;
    height: 6.5%;
    margin-left: auto;
    cursor: pointer !important;
}

.dx-item {
    cursor: auto;
}

.IconFormularioAsociado {
    position: sticky;
    top: 0;
    font-size: 30px;
    margin: 15px 0px auto 20px;
}

.AccordionAudienciaEvaluations {
    margin: 60px 0px 0px 0px;
}

.txtDescripcionEvaluacion {
    margin: 30px 0px 20px 0px;
}

.AudienciaAjustes {
    margin: 35px 0px 0px 0px;
}

.btnAtras {
    margin: 30px 0px 0px 50px
}

.btnAtrasNewEvalaution {
    margin: 30px 0px 0px 40px
}

.ContenedorEvaluations {
    margin: 30px 0px 30px 190px;
    width: 95%;
    height: 780px;
    overflow-y: auto;
}

.cardEditEvaluation {
    margin: 30px 0px 30px 190px;
    width: 95%;
    height: auto;
    overflow-y: auto;
    scroll-behavior: auto;
}

.cardEditEvaluation {
    margin: 30px 0px 30px 50px;
    width: 95%;
    height: auto;
    overflow-y: auto;
    scroll-behavior: auto;
}

::-webkit-scrollbar {
    width: 7px;
}

::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: #d2d2d2;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: #d2d2d2;
}

.tituloEdit {
    width: 95%;
    margin: 20px 0px 20px 35px;
}

.descripcionEdit {
    margin: 20px 0px 30px 0px;
}

.gridCollaborators {
    display: flex;
    margin: 30px 0px 0px 0px;
}

.dgvEvaluators {
    max-width: 100% !important;
}

.dgvEvaluateds {
    max-width: 100% !important;
    margin: 0px 0px 0px 0px;
}

.FieldsetEvaluateds {
    width: 49%;
}

.FieldsetEvaluators {
    width: 49%;
    margin: 0px 0px 0px 55px;
}

.dx-fileuploader-files-container {
    display: none;
}

.uploadTemplate {
    margin: 30px 0px 0px 25px;
}

.btnEditarPreguntas { 
    margin: 0px 0px 0px 25px;
     background-color: #337fe3 !important;
}

.btnDescargarTemplate { 
    margin: 0px 0px 0px 25px;
     background-color: #e9af33 !important;
}

.form-avatar {
    cursor: pointer;
    border-radius: 10%; 
    margin: 20px 0px 0px 30px;
}

.imgDiv {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-color: #000
}

.btnDeployeEvaluationTrue{
    margin: 0px 0px 0px 25px;
     background-color: #e93c33 !important;
     color: #fffffa;
}

.btnDeployeEvaluationFalse{
    margin: 0px 0px 0px 25px;
}