.separador {
    margin: 16px 0px;
    flex-shrink: 0;
    border-width: 0px 0px thin;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.12);
}
.separadorTitulo {
        font-size: 1.0938rem;
}

.winNewForm{
    padding-top: 0px;
    flex: 1 1 auto;
    overflow-y: auto;
    padding: 20px 24px;
}

.Botones {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    padding: 8px;
    -webkit-box-pack: end;
    justify-content: flex-end;
    flex: 0 0 auto;
}

.btnOpcionesForm{// display: inline-block;
    margin: .5rem;
    width: 20%;
    height: 38px;
    font-size: 19px;
    background-color: #ffffff;
    color: #292828;
    border: 1px solid #949191;
    border-radius: 10px;
    cursor: pointer;
    // text-align: center;
}

.btnOpcionesForm:active {
    background-color: #949191;
}

.Guardar:hover{
    background-color: #464646;
    color: #ddd;
    border: 1.8px solid #000;
    transition: background-color 400ms, border 400ms;
}

.Cancelar:hover{
    background-color: #cecbcb;
    border: 1.8px solid #000;
    transition: background-color 400ms, border 400ms;
}