.tgEvaluacion {
    display: block;
    width: auto;
    height: auto;
    border: 1px solid #ddd;
    border-radius: 8px;
}

.tgTitle {
    font-size: 12px;
    transition: font-size .3s, color .4s;
}
.tgEvaluacion:hover .tgTitle{
    font-size: 13px;
    color: #fa7e02;
    transition: font-size .4s, color .4s;
}

.iconEvaluacion {
    display: inline-block;
    padding-left: 15px;
    text-indent: 0;
    width: 40%;
    object-fit: contain;
}

.dx-popup-content{
    height: auto;
}

.IconEvaluacion{
    position: sticky;
    top: 0;
    font-size: 30px;
    margin: 3px 0px auto 20px; /* Ajusta el margen derecho según sea necesario */
}

.textDescription {
    white-space: normal;
    width: min-content;
}