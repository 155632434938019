// .titleCards {
//     margin: 0px 0px 40px 0px
// }
// .tasks {
//     margin: '0px 0px 40px 0px' 
// }


.cardVideo {
    // display: flex;
    background-color: white;
    border: 2px solid #ddd; /* Borde gris */
    border-radius: 15px;
    padding: 5px 5px 5px 5px;
    // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.263); /* Sombra suave */
    width: 30.5%;
    height: auto;
    margin: 1% .5% 1% .5%;
    // display: flex;
    cursor: pointer;
}

.cardVideo:active {
    background-color: #f1f1f1;
}
.cardVideo-content {
    margin-top: 0;
    display: grid;
    font-size: 16px;
    // flex-direction: column; /* Cambio a dirección de columna */
}

.cardFileElement {
    // display: flex;
    background-color: white;
    border: 2px solid #ddd; /* Borde gris */
    border-radius: 15px;
    padding: 5px 5px 5px 5px;
    // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.263); /* Sombra suave */
    width: 25.5%;
    height: auto;
    margin: 1% .5% 1% .5%;
    // display: flex;
    cursor: pointer;
}

.cardFileElement:active {
    background-color: #f1f1f1;
}
.cardFileElement-content {
    margin-top: 0;
    display: grid;
    font-size: 17px;
    text-align: center;
    // flex-direction: column; /* Cambio a dirección de columna */
}

.icon_DeleteFile{
    font-size: 25PX;
    margin: 1.5% 0% 0% 0%;
    border-radius: 15px;
    cursor: pointer;
}

.icon_DeleteFile:active{
    background-color: #949191;
}

.imgVideo{
    width: 100%;
    height: 15rem;
    border-radius: 15px;
    cursor: pointer !important;
}

.copiarURL{
    display: inline-block;
    margin: 15px 0px 0px 17px;
    width: 42%;
    height: 38px;
    font-size: 19px;
    background-color: #ffffff;
    color: #292828;
    border: 1px solid #ddd;
    border-radius: 10px;
    cursor: pointer !important;
    text-align: center;
}

.copiarURL:hover{
    background-color: #f1f1f1;
    border: 1.8px solid #000;
    transition: background-color 400ms, border 400ms;
}

.copiarURL:active {
    background-color: #949191;
}

.eliminarURL{
    display: inline-block;
    margin: 15px 0px 0px 34px;
    width: 42%;
    height: 40px;
    font-size: 19px;
    background-color: #ffffff;
    color: #292828;
    border: 1px solid #ddd;
    border-radius: 10px;
    cursor: pointer !important;
    text-align: center;
}

.eliminarURL:hover{
    background-color: #f1f1f1;
    border: 1.8px solid #000;
    transition: background-color 400ms, border 400ms;
}

.eliminarURL:active {
    background-color: #ddd;
}

.scrolldiv{
    display: flex;
    flex-wrap: wrap;
}