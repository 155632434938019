.window2{
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    padding: 8px;
    flex: 0 0 auto;
    -webkit-box-pack: justify;
    justify-content: space-between;

}

.window1{
    flex: 1 1 auto;
    overflow-y: auto;
    padding: 16px 24px;
    // border-top: 1px solid rgba(0, 0, 0, 0.12);
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.windowPrincipal{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.TituloCategoria{
    width: 75%;
    height: 100%;
}

.btnOpcionesCategorias{
    // display: inline-block;
    margin: .5rem;
    width: 10%;
    height: 38px;
    font-size: 19px;
    background-color: #ffffff;
    color: #292828;
    border: 1px solid #949191;
    border-radius: 15px;
    cursor: pointer;
    // text-align: center;
}

.Guardar:hover{
    background-color: #464646;
    color: #ddd;
    border: 1.8px solid #000;
    transition: background-color 400ms, border 400ms;
}

.Cancelar:hover{
    background-color: #cecbcb;
    border: 1.8px solid #000;
    transition: background-color 400ms, border 400ms;
}

.btnOpcionesCategorias:active {
    background-color: #949191;
}

.ImgCategoriesEdit{
    width: 70px;
    height: 70px;
    object-fit: contain;
    margin: 0px 5px 0px 0px;
    cursor: pointer;
}