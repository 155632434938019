.titleCards {
    margin: 0px 0px 40px 0px;
}

.card {
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 15px;
    padding: 16px;
    width: auto;
}

.card-title {
    margin-top: 0;
    margin-bottom: 8px;
    font-size: 1.5rem;
}

.card-content {
    margin-top: 0;
    display: grid;
}

.card .btnNuevaTarea {
    font-size: 20px;
    margin: 0px 0px 0px 0px; 
    width: 100%;
    height: 100%;
    border: none;
    grid-row: 3;
    grid-column: 1;
}

.dx-icon-edit {
    font-size: 30px;
    cursor: pointer !important;
    grid-row: 1;
    grid-column: 1;
    margin: 2% 0% 0% 96%;
}
.dx-icon-overflow{
    font-size: 30px;
    cursor: pointer;
    grid-row: 1;
    grid-column: 1;
    margin: 2% 0% 0% 93%;
}

.tbTitulo {
    width: 95%;
    grid-row: 1;
    grid-column: 1;
    border-radius: 15px;
    font-size: 30px;
}
.tituloCuestionario {
    font-size: 30px;
    grid-row: 1;
    grid-column: 1;
    width: 80%;
}


.grid-container {
    display: grid;
    grid-template-rows: auto;
    gap: 10px; 
}

.BoxPreguntas{
    grid-row: 2;
    grid-column: 1;
    width: 80%;
}

.tbPregunta {
    width: 100%;
    grid-row: 2;
    grid-column: 1;
    border-radius: 15px;
    font-size: 30px;    
}

.btnAgregar{
    width: 15%;
    margin: 2% 0% 0% 0%;
}

.btnCancelar{
    width: 15%;
    margin: 2% 0% 0% 2%;
}


.cardPregunta {
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 15px;
    padding: 16px;
    width: 125%;
    height: auto;
    margin: 4% 0% 4% 0% ;
    cursor: pointer;
}

.cardPregunta:hover {
    background-color: #f1f1f1;
}
.cardPregunta-content {
    margin-top: 0;
    display: grid;
    font-size: 16px;
    width: 94%;
}
.cardPregunta:active {
    background-color: #e8e6e6c0;
}


.btnTipoRespuesta{
    display: inline-block;
    margin: .3rem;
    width: 95%;
    height: 38px;
    font-size: 19px;
    background-color: #ffffff;
    color: #292828;
    border: 1px solid #949191;
    border-radius: 15px;
    cursor: pointer;
}

.btnTipoRespuesta:hover{
    background-color: #f7f6f6;
    border: 1.8px solid #000;
    transition: background-color 400ms, border 400ms;
}

.btnTipoRespuesta:active {
    background-color: #949191;
}

.btnIcon{
    margin: 5px 15px auto 20px
}

.Campo{
    border-radius: 15px;
}

.btnNuevaOpcion{
    display: inline-block;
    margin: .5rem;
    width: 30%;
    height: 38px;
    font-size: 19px;
    background-color: #ffffff;
    color: #292828;
    border: 1px solid #949191;
    border-radius: 15px;
    cursor: pointer;
}

.btnNuevaOpcion:hover{
    background-color: #f7f6f6;
    border: 1.8px solid #000;
    transition: background-color 400ms, border 400ms;
}

.btnNuevaOpcion:active {
    background-color: #949191;
}

.iconNuevo{
    margin: 5px 5px auto 15px
}

.obligatiorio{
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    padding: 8px;
    flex: 0 0 auto;
    -webkit-box-pack: justify;
    justify-content: space-between;

}

.part2{
    flex: 1 1 auto;
    overflow-y: auto;
    padding: 16px 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.part1{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}


.btnGuardar{
    display: flex;
    margin: 1% 0px -1% 79%;
    width: 10%;
    height: 49px;
    font-size: 19px;
    background-color: #ffffff;
    color: #292828;
    border: 1px solid #949191;
    border-radius: 15px;
    cursor: pointer;
}

.btnGuardar:hover{
    background-color: #f7f6f6;
    border: 1.8px solid #000;
    transition: background-color 400ms, border 400ms;
}

.btnGuardar:active {
    background-color: #949191;
}


.labels{
    font-size: 20px;
    width: auto;
}

.optionsList{
    display: flex;
}

.optionList{
    width: 95%;
}

.icon_optionList{
    font-size: 25PX;
    margin: 30px 0px 0px 10px;
    border-radius: 15px;
    cursor: pointer;
}
.icon_optionList:hover{
    background-color: #afafaf75;
    transition: background-color 400ms, border 400ms;
}
.icon_optionList:active{
    background-color: #949191;
}




.listasOpciones{
    .dx-radio-value-container{
        margin: 0px 0px 0px 15px;
    }
}
.listasOpciones{
    .dx-item-content{
        margin: 0px 0px 0px 30px;
    }
}