.DivPrincipalEvaluacion {
    flex: 1;
    padding: 16px 32px;
}

.TarjetaPregunta {
    background-color: rgb(255, 255, 255);
    color: rgb(23, 43, 77);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 16px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 2px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    overflow: hidden;
    width: 100%;
    padding: 16px;
    margin-top: 16px;
    margin-bottom: 16px;
    box-sizing: border-box;
}

.TarjetaPreguntaCorrecta {
    background-color: rgb(255, 255, 255);
    color: rgb(23, 43, 77);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 16px;
    box-shadow: rgba(38, 188, 4, 0.837) 0px 2px 2px, rgba(31, 157, 3, 0.713) 0px 0px 0px 1px;
    overflow: hidden;
    width: 100%;
    padding: 16px;
    margin-top: 16px;
    margin-bottom: 16px;
    box-sizing: border-box;
}

.TarjetaPreguntaIncorrecta {
    background-color: rgb(255, 255, 255);
    color: rgb(23, 43, 77);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 16px;
    box-shadow: rgba(207, 1, 1, 0.837) 0px 2px 2px, rgba(205, 3, 3, 0.713) 0px 0px 0px 1px;
    overflow: hidden;
    width: 100%;
    padding: 16px;
    margin-top: 16px;
    margin-bottom: 16px;
    box-sizing: border-box;
}

.Pregunta {
    margin: 0px;
    font-family: "Twemoji Country Flags", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
    font-size: 1rem;
    line-height: 1.5;
    font-weight: 700;
    color: rgb(41, 38, 38);
}

.divRespuestaTexto {
    font-family: "Twemoji Country Flags", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    color: rgb(23, 43, 77);
    box-sizing: border-box;
    cursor: text;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;    
    width: 100%;
    position: relative;
    border-radius: 16px;

    background-color: rgb(255, 255, 255);
    color: rgb(23, 43, 77);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 16px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 2px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    overflow: hidden;
    width: 100%;
    margin-top: 16px;
    margin-bottom: 16px;
}

.divRespuestaAreaTexto {
    font-family: "Twemoji Country Flags", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    color: rgb(23, 43, 77);
    box-sizing: border-box;
    cursor: text;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;    
    width: 100%;
    position: relative;
    border-radius: 16px;
    background-color: rgb(255, 255, 255);
    color: rgb(23, 43, 77);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 16px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 2px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    overflow: hidden;
    width: 100%;
    margin-top: 16px;
    margin-bottom: 16px;
}

.RespuestaTexto {
    font: inherit;
    letter-spacing: inherit;
    color: currentcolor;
    border: 0px;
    box-sizing: content-box;
    background: none;
    height: 1.4375em;
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    display: block;
    min-width: 0px;
    width: 100%;
    animation-name: mui-auto-fill-cancel;
    animation-duration: 10ms;
    padding: 16.5px 14px;
    border-radius: 16px;
}

.buttonCompletarEvaluacion {
    background-color: black;
    color: white;
    font-size: 17px;
    border-radius: 5px;
    margin: 16px 0px;
    text-align: center;
    height: 40px;
    width: 250px;
    cursor: pointer;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.btnContinuar {
    background-color: black;
    color: white;
    font-size: 17px;
    border-radius: 5px;
    margin: 16px 0px;
    text-align: center;
    height: 40px;
    width: 250px;
    cursor: pointer;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.botonCancelar{
    border-radius: 14px;
    box-shadow: none;
}

.botonAceptar {
    border-radius: 14px;
    box-shadow: none;
    align-self: flex-end;
}
