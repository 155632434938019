.barraSeparadora {
    margin-top: 20px;
    margin-bottom: 20px;
    flex-shrink: 0;
    border-style: solid;
    width: 2px;
    height: 150px;
    border-width: 2px;
    border-color: rgb(0, 0, 0);
    align-self: center;
}

.barraSeparadoraActivo {
    margin-top: 20px;
    margin-bottom: 20px;
    flex-shrink: 0;
    border-style: solid;
    width: 2px;
    height: 150px;
    border-width: 2px;
    border-color: rgb(76, 175, 80);
    align-self: center;
}

.botonCategoria {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    outline: 0px;
    border: 0px;
    margin: 0px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    font-weight: 600;
    font-family: "Twemoji Country Flags", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
    font-size: 0.875rem;
    line-height: 1.75;
    min-width: 64px;
    padding: 6px 8px;
    border-radius: 16px;
    color: rgb(41, 38, 38);
    text-transform: none;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex: 1 1 0%;
    flex-direction: column;
    box-shadow: 0 0px 0px rgba(0, 0, 0, .24);
}

.botonCategoriaActivo {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    outline: 0px;
    border: 0px;
    margin: 0px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    font-weight: 600;
    font-family: "Twemoji Country Flags", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
    font-size: 0.875rem;
    line-height: 1.75;
    min-width: 64px;
    padding: 6px 8px;
    border-radius: 16px;
    color: rgb(41, 38, 38);
    text-transform: none;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex: 1 1 0%;
    flex-direction: column;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .24);
}

.indicadorCategoriaActual {
    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    flex-shrink: 0;
    font-family: "Twemoji Country Flags", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
    overflow: hidden;
    user-select: none;
    color: rgb(244, 245, 247);
    width: 50px;
    height: 50px;
    text-decoration: none;
    line-height: calc(25px);
    font-size: calc(25px);
    background-color: rgb(41, 38, 38);
    margin: 20px 0px;
    border-radius: 50%;
    align-self: center;
}

.imgCategoria {
    border-radius: 58%;
    object-fit: cover;
}

.textoNombreCategoria {
    margin: 8px 0px 0px;
    font-weight: 600;
    font-size: 1.0625rem;
    font-family: "Twemoji Country Flags", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
    line-height: 1.6;
    color: rgb(23, 43, 77);
    white-space: nowrap;
}

.textoTareasPendiente {
    margin: 8px 0px 0px;
    font-family: "Twemoji Country Flags", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    color: rgb(107, 119, 140);
    white-space: nowrap;
}

.scrollPrincipal {
    display: flex;
    flex-direction: column;
    max-height: 80vh;
    margin: 50px 0px 0px 0px;
}