.divPrincipal {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 16px 32px;
}

.scrollTarea {
    display: flex;
    flex: 1;
}

.divContenido{
    width: 70%;
    justify-content: center;
    flex-direction: column;
    align-self: center;
    background-color: rgb(255, 255, 255);
    border-radius: 16px;
    padding-top: 16px;
    padding-bottom: 16px;
}

.contenidoTarea {
    padding: 16px 32px;
}

.buttonAtras2{
    padding: 16px 16px;
}

.buttonCompletarTarea{
    background-color: black;
    color: white;
    font-size: 17px;
    border-radius: 5px;
    margin: 16px 16px;
    text-align: center;
    height: 40px;
    width: 250px;
    cursor: pointer;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.botonCancelar{
    border-radius: 14px;
    box-shadow: none;
}

.botonAceptar {
    border-radius: 14px;
    box-shadow: none;
    align-self: flex-end;
}

.VideoTarea {
    width: 60vh;
    height: 50vh;
    max-width:734px;
    max-height:368px;
    display: table;
    margin-left: auto;
    margin-right: auto;
}

.dx-gallery .dx-gallery-nav-button-next, .dx-gallery .dx-gallery-nav-button-prev {
    position: absolute;
    top: 50%;
    bottom: 0;
    width: 7%;
    height: 10%;
    background: 0 0;
    cursor: pointer;
    text-align: center;
    background-color: #ff5722;
    border-radius: 25px;
}

.dx-gallery .dx-gallery-nav-button-next::before, .dx-gallery .dx-gallery-nav-button-prev::before {
    display: block;
    width: 32px;
    top: 50%;
    margin-top: -16px;
    left: 50%;
    margin-left: -16px;
    position: absolute;
    z-index: 10;
    clear: both;
    font-size: 32px;
    color: #fff;
}

.dx-gallery .dx-gallery-nav-button-next::after, .dx-gallery .dx-gallery-nav-button-prev::after {
    content: "";
    position: absolute;
    width: 0px;
    height: 0px;
    background: rgba(0, 0, 0, .54);
    border-radius: 50%;
    top: 50%;
    margin-top: -16px;
}

.cardFile{
    margin: 0% 15% 0% 15%;
    width: 45rem;
    height: 25rem;
    background-color: white;
    border: 2px solid #ddd;
    border-radius: 15px;
    padding: 5px 5px 5px 5px;
    cursor: pointer;
}

.cardFile-content{
    margin-top: 0;
    display: grid;
    font-size: 17px;
    text-align: center;
}
